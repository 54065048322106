body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Avenir-Medium';
  src: url('fonts/Avenir-Medium.eot');
  src: url('fonts/Avenir-Medium.eot?#iefix') format('embedded-opentype'), url('fonts/Avenir-Medium.woff') format('woff'),
    url('fonts/Avenir-Medium.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir-Roman';
  src: url('fonts/Avenir-Roman.eot');
  src: url('fonts/Avenir-Roman.eot?#iefix') format('embedded-opentype'), url('fonts/Avenir-Roman.woff') format('woff'),
    url('fonts/Avenir-Roman.ttf') format('truetype');
  font-style: normal;
}
@font-face {
  font-family: 'BrandonGrotesque-Bold';
  src: url('fonts/BrandonGrotesque-Bold.eot');
  src: url('fonts/BrandonGrotesque-Bold.eot?#iefix') format('embedded-opentype'),
    url('fonts/BrandonGrotesque-Bold.woff') format('woff'), url('fonts/BrandonGrotesque-Bold.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'FatFrank-Regular';
  src: url('fonts/FatFrank-Regular.eot');
  src: url('fonts/FatFrank-Regular.eot?#iefix') format('embedded-opentype'),
    url('fonts/FatFrank-Regular.woff') format('woff'), url('fonts/FatFrank-Regular.ttf') format('truetype');
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
